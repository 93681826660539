import "./scss/index.scss";

import React, { useContext } from "react";
import { Link  } from "react-router-dom";

import { Button} from "../../components";
import { BASE_URL } from "../../core/config";

import { CartContext } from "../../components/CartProvider/context";
import { Trans } from 'react-i18next';


/*
const aa : React.FC = () => { 
      
      const { clear: clearCart } = useContext(CartContext);
      clearCart();
      return (<p>Signed Out</p>);
}; */
const View: React.FC = () => {  
  const { clear: clearCart } = useContext(CartContext);

  React.useEffect(() => { clearCart(); }, []);
 
 // const aa = clearCart();
  return ( 
    
    <div className="order-confirmation">
      <h3>
        <Trans>Thank you for</Trans><br /><Trans>your order!</Trans>
      </h3>
      <p className="order-confirmation__info">
        <Trans>An email will be sent to you when the payment is processed.</Trans>
        <br />
        <Trans>And will notify you when the order has been</Trans>
        <br />
        <Trans>shipped.</Trans>
      </p>
      <div className="order-confirmation__actions">
        <Link to={BASE_URL}>
          <Button secondary><Trans>Continue Shopping</Trans></Button>
        </Link>        
      </div>
    </div>
  );

};

export default View;
