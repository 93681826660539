import * as React from "react";

import { Button } from "../..";
import { Trans } from 'react-i18next';

const Empty: React.FC<{ overlayHide(): void }> = ({ overlayHide }) => (
  <div className="cart__empty">
    <h4><Trans>Your bag is empty</Trans></h4>
    <p>
      <Trans>You haven’t added anything to your bag. We’re sure you’ll find something in our store</Trans>
    </p>
    <div className="cart__empty__action">
      <Button secondary onClick={overlayHide}>
       <Trans>Continue Shopping</Trans>
      </Button>
    </div>
  </div>
);

export default Empty;
