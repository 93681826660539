import "./scss/index.scss";

import React from "react";
import { generatePath, Link, RouteComponentProps } from "react-router-dom";

import { guestOrderDetailsUrl } from "../../../app/routes";
import { Button, NotFound } from "../../../components";
import { BASE_URL } from "../../../core/config";
import { userOrderDetailsUrl } from "../../../userAccount/routes";
import { Trans } from 'react-i18next';


type TState = {token: any, id: number};
const View: React.FC<RouteComponentProps> = ({
  history: {
    location: { state },
  }, 
}) => { 
  if (!state) {
    alert(state);
    return <NotFound />;
  }

  const { token, id } = state as TState;
  const guest = !id;
  const orderDetailsRef = guest
    ? generatePath(guestOrderDetailsUrl, { token })
    : generatePath(userOrderDetailsUrl, { id });

  return (
    <div className="order-confirmation">
      <h3>
        <Trans>Thank you for</Trans><br /> <Trans>your order!</Trans>
      </h3>
      <p className="order-confirmation__info">
        <Trans>We’ve emailed you a order confirmation</Trans>
        <br />
        <Trans>and we’ll notify you when order has</Trans>
        <br />
        <Trans>shipped.</Trans>
      </p>
      <div className="order-confirmation__actions">
        <Link to={BASE_URL}>
          <Button secondary><Trans>Continue Shopping</Trans></Button>
        </Link>
        <Link to={orderDetailsRef}>
          <Button><Trans>Order Details</Trans></Button>
        </Link>
      </div>
    </div>
  );
};

export default View;
