import React, { useContext } from 'react';

import {  useSignOut } from "@sdk/react";
import { CheckoutContext } from "../../checkout/context";
import { CartContext } from "../CartProvider/context";



const SignOutComp: React.FC = () => {
   
  const [signOut] = useSignOut();
  const { clear: clearCart } = useContext(CartContext);
  const { clear: clearCheckout } = useContext(CheckoutContext);

  const handleSignOut = () => {
    signOut();
    clearCart();
    clearCheckout();
  };
handleSignOut();
return (<p>Signed Out</p>);
};
export default SignOutComp;