/*
 * Ecomerso (c) 2020. All rights reserved.
 * 
 */
import { language } from "./constants";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
console.log(language);
i18n.init({
  lng: "en", // set sv for Swedish
  // we init with resources
  resources: {
    sv: {
      translations: {
        "Ecomerso account": "Ecomerso Konto",
        "Sign in to account": "Logga in på konto",
        "Register new account": "Registrera nytt konto",
        "Have you forgotten your password?":"Har du glömt ditt lösenord?",
         "Loading":"Läser in",
         "Sign in": "Logga in",
        "Click Here": "Klicka här",
        "My Account": "Mitt konto",
        "Account": "Konto",
        "Order History": "Beställningshistorik",
        "Address Book": "Adressbok",
        "Payment Options": "Betalningsalternativ",
        "Log Out": "Logga ut",
        "Register": "Registrera",
        "Index Number": "Indexnummer",
        "Products Ordered": "Produkter beställda",
        "Date of Order": "Orderdatum",
        "Value": "Värde",
        "Status": "Status",
        "Unfulfilled": "Ouppfylld",
        "Your order": "Du beställer",
        "Products": "Produkter",
        "Price": "Pris",
        "Size": "Storlek",
        "Quantity": "Kvantitet",
        "Total Price": "Totalbelopp",
        "Subtotal": "Delsumma",
        "Delivery Cost": "Leverans kostnad",
        "Total Cost": "Total kostnad",
        "Shipping Address": "Leveransadress",
        "Go back to Order History": "Gå tillbaka till orderhistorik",
        "Search": "Sök",
        "My Bag": "Min väska",
        "Items": "artiklar",
        "Go To Bag": "Gå till väska",
        "Checkout": "Kolla upp",
        "Shopping bag": "Shoppingkasse",
        "Proceed to Checkout": "Fortsätt till kassan",
        "Shipping Method": "Frakt metod",
        "Continue to billing": "Fortsätt till fakturering",
        "Billing Address": "Fakturaadress",
        "Same as Shipping Address": "Samma som leveransadressen",
        "Deliver to this address": "Leverera till den här adressen",
        "Add New Address": "Lägg till ny adress",
        "Use this address as new": "Använd den här adressen som ny",
        "address": "adress",
        "Proceed to Payment": "Fortsätt till betalning", //aaa
        "Payment Method": "Betalningsmetod",
        "Do you have a discount code?": "Har du en rabattkod?",
        "Credit Card": "Kreditkort",
        "Cart summary": "Kundvagnsöversikt",
        "Delivery": "Leverans",
        "Grand total": "Stor total",
        "Review your order": "Granska din beställning",  
        "Continue to Review Your Order":"Fortsätt att granska din beställning",
        "Place your order": "Lägg din beställning",
        "Thank you for": "Tack för",
        "your order!": "din beställning!",
        "We’ve emailed you an order confirmation.": "Vi har skickat en orderbekräftelse via e-post till dig.",
        "We’ll notify you when the order has been": "Vi meddelar dig när beställningen har",
        "shipped.": "skickats",
        "Continue Shopping": "Fortsätt handla",
        "Order Details": "Orderdetaljer",
        "Continue to Shipping": "Fortsätt att levereras",
        "Return to shopping":"Återgå till shopping",
        "MY DATA":"MIN DATA",
        "Index Number":"Indexnummer",
        "Products Ordered":"Produkter beställda",
        "Date of Order":"Orderdatum",
        "Value":"Värde",
        "Personal details":"Personliga detaljer",
        "My Password":"Mitt Lösenord",
        "Go to my bag":"Gå till min väska",
        "First Name": "Förnamn",
        "Last Name": "Efternamn",
        "Old Password": "Gammalt lösenord",
        "New Password": "nytt lösenord",
        "Confirm Password": "Bekräfta lösenord",
        "Cancel":"Avbryt",
        "Save":"Spara",
        "Company Name (optional)": "Företagsnamn (ej obligatorisk)",
        "Phone": "",
        "Address Line 1": "Adressrad 1",
        "Address Line 2": "Adressrad 2",
        "City": "Stad",
        "ZIP/Postal Code": "",
        "Street Name": "Gatunamn",
        "Sorry, but we couldn’t match any search results for": "Tyvärr, men vi kunde inte matcha några sökresultat för",
        "Don’t give up - check the spelling, think of something less specific and then use the search bar above.": "Ge inte upp - kolla stavningen, tänk på något mindre specifikt och använd sedan sökfältet ovan.",
        "Reset your password": "Återställ ditt lösenord",
        "Please provide us your email address so we can share you a link to reset your password": "Vänligen ange din e-postadress så att vi kan dela en länk för att återställa ditt lösenord",
        "Reset password": "Återställ lösenord",
        "DESCRIPTION": "BESKRIVNING",
        "ATTRIBUTES": "ATTRIBUT",
        "Add to basket": "Lägg i korgen",
        "Other products in this category": "Andra produkter i denna kategori",
        "You haven’t added anything to your bag. We’re sure you’ll find something in our store": "Du har inte lagt till något i din väska. Vi är säkra på att du hittar något i vår butik",
        "Your bag is empty": "Din väska är tom",
      } 
    },
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;