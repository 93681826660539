import * as React from "react";
import { Trans } from 'react-i18next';

const CostRow: React.FC<{
  mediumScreen: boolean;
  heading: string;
  cost: React.ReactNode;
}> = ({ mediumScreen, heading, cost }) => (
  <tr>
    <td colSpan={mediumScreen ? 4 : 3} className="cart-table__cost">
      <Trans>{heading}</Trans>
    </td>
    <td colSpan={2}>{cost}</td>
  </tr>
);

export default CostRow;
