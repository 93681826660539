import * as React from "react";
import { Link } from "react-router-dom";

import { baseUrl } from "../app/routes";
import Button from "./Button";
import { Trans } from 'react-i18next';


const EmptyCart: React.FC<{}> = () => (
  <div className="cart-page__empty">
    <h4><Trans>Your bag is empty</Trans></h4>
    <p>
      <Trans>You haven’t added anything to your bag. We’re sure you’ll find something in our store</Trans>
    </p>
    <div className="cart-page__empty__action">
      <Link to={baseUrl}>
        <Button secondary><Trans>Continue Shopping</Trans></Button>
      </Link>
    </div>
  </div>
);

export default EmptyCart;
