export const baseUrl = "/checkout/";
export const shippingAddressBaseUrl = `shipping-address/`;
export const shippingOptionsBaseUrl = `shipping-options/`;
export const billingBaseUrl = `billing-address/`;
export const paymentBaseUrl = `payment/`;
export const reviewBaseUrl = `review/`;
export const shippingAddressUrl = `${baseUrl}${shippingAddressBaseUrl}:token?/`;
export const shippingOptionsUrl = `${baseUrl}${shippingOptionsBaseUrl}:token?/`;
export const billingUrl = `${baseUrl}${billingBaseUrl}:token?/`;
export const paymentUrl = `${baseUrl}${paymentBaseUrl}:token?/`;
export const reviewUrl = `${baseUrl}${reviewBaseUrl}:token?/`;
