import "./scss/index.scss";

import * as React from "react";
import { BASE_URL } from "../../core/config";

import { Redirect } from "react-router";


interface NotFoundProps {
  message?: string;
}

const NotFound: React.FC<NotFoundProps> = () => {

return <Redirect to={BASE_URL} />;

};

export default NotFound;
