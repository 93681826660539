import { styled } from "@styles";
import { css, keyframes } from "styled-components";
import { Position, TransitionState } from "./types";

interface IStyleProps {
  open: boolean;
  position: Position;
  state: TransitionState;
  transparent?: boolean;
}

const getTranslate = (side: "left" | "right") =>
  side === "left" ? "-100%" : "100%";

const slideAnimation = (open: boolean, side: "left" | "right") => {
  const initialValue = open ? getTranslate(side) : 0;
  const endValue = open ? 0 : getTranslate(side);
  return keyframes`
    from {
      transform: translateX(${initialValue});
    }
    to {
      transform: translateX(${endValue});
    }`;
};

const opacity = {
  entered: 1,
  entering: 0,
  exited: 0,
  exiting: 0,
  unmounted: 0,
};

const justify = {
  center: "center",
  left: "flex-start",
  right: "flex-end",
};

const lightboxWidth = {
  center: "auto",
  left: "100%",
  right: "100%",
};

const lightboxHeight = (width: number) => ({
  center: `${width}px`,
  left: "auto",
  right: "auto",
});

export const Lightbox = styled.div<IStyleProps>`
  display: flex;
  position: relative;
  width: ${({ position, theme: { modal } }) =>
    lightboxHeight(modal.modalWidth)[position]};
  min-height: ${props => props.theme.modal.modalMinHeight}px;
  height: ${({ position }) => lightboxWidth[position]};
  background-color: ${props => props.theme.colors.white};
  ${({ open, position }) => {
    if (position === "left" || position === "right") {
      return css`
        ${position}: 0;
        transform: translateX(${getTranslate(position)});
        animation: ${slideAnimation(open, position)} 0.4s both;
        animation-delay: ${open ? ".1s" : 0};
      `;
    }
  }}
`;
Lightbox.displayName = "S.Lightbox";

export const Overlay = styled.div<IStyleProps>`
  display: flex;
  position: fixed;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  z-index: 2;
  transition: opacity 0.2s ease;
  transition-delay: ${({ open }) => (open ? 0 : ".4s")};
  background-color: ${({ transparent, theme }) =>
    transparent ? "" : theme.colors.overlay};
  align-items: center;
  justify-content: ${({ position }) => justify[position]};
  opacity: ${({ state }) => opacity[state]};
  
  /* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
			padding-top: 25rem;
}


/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
		padding-top: 20rem;
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
		padding-top: 20rem;
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
		padding-top: 14rem;
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
		padding-top: 9.5rem;	
}

/* ----------- Galaxy S3 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 2) {
		padding-top: 15rem;
}


/* ----------- Galaxy S4, S5 and Note 3 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) {
		padding-top: 15rem;
}


/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) {

}

/* ----------- Google Pixel ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) {

}


/* ----------- Google Pixel XL ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) {

}

/* ----------- HTC One ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) {

}

/* ----------- Windows Phone ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 480px) 
  and (device-height: 800px) {

}

/* ----------- Apple Watch ----------- */
@media
  (max-device-width: 42mm)
  and (min-device-width: 38mm) { 

}
/* ----------- Moto 360 Watch ----------- */
@media 
  (max-device-width: 218px)
  and (max-device-height: 281px) { 

}
`;
Overlay.displayName = "S.Overlay";
