import React, { useEffect, useState } from "react";

import { Elements, StripeProvider } from "react-stripe-elements";

import { ProviderProps } from "../../View";
import CardForm from "./CardForm";
import { Button } from "../../../../../components";
import { Trans } from 'react-i18next';

export const Stripe = (props: ProviderProps) => {
  const [stripe, setStripe] = useState(null);

  const apiKey = props.paymentGatewayConfig.find(
    ({ field }) => field === "api_key"
  ).value;
 let loading;
 let formRef;
  useEffect(() => {
    if (!(window as Window).Stripe) {
      const stripeJs = document.createElement("script");

      stripeJs.src = props.paymentGatewayHref;
      stripeJs.async = true;
      stripeJs.onload = () => {
        setStripe((window as Window).Stripe(apiKey));
      };

      document.body.appendChild(stripeJs);
    } else {
      setStripe((window as Window).Stripe(apiKey));
    }
   loading = props.loading;
   formRef = props.formRef;
  }, []);

  return (
   <div>
    <StripeProvider stripe={stripe}>
      <Elements>
        <CardForm {...props} />
  
      </Elements>
            
    </StripeProvider>
              <Button
                      type="submit"
                      disabled={loading}
                      onClick={() => {
                        formRef.current.dispatchEvent(
                          new Event("submit", { cancelable: true })
                        );
                      }}
                    >
                      <Trans>Continue to Review Your Order</Trans>
                    </Button>
</div>
  );
};
