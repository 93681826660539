import * as React from "react";
import { Trans } from 'react-i18next';

export const NothingFound: React.FC<{ search: string }> = ({ search }) => (
  <div className="search__products--not-found">
    <p className="u-lead u-lead--bold u-uppercase">
      <Trans>Sorry, but we couldn’t match any search results for</Trans>: {search}
    </p>
    <p>
      <Trans>Don’t give up - check the spelling, think of something less specific and
      then use the search bar above.</Trans>
    </p>
  </div>
);

export default NothingFound;
