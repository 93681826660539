import  * as React from "react";
import "./scss/index.scss";
import * as appPaths from "../../app/routes";
import ReactSVG from "react-svg";
import {
  Overlay, 
  OverlayContextInterface,
  SignOutComp
} from "..";

import { Link } from "react-router-dom";
import closeImg from "../../images/x.svg";
import cartImg from "../../images/user.svg";

import { Trans } from 'react-i18next';


class MenuDropdown extends React.Component<
  { overlay: OverlayContextInterface},
  { active: boolean;}
> {
 status = null;

 constructor(props) {
    super(props);
    this.state = {active: false};

  }

handleSignOut(e){
this.status = <SignOutComp />;
this.setState({ active: true }); 
}
  
  render() {
  const { overlay } = this.props;
  let signOutComp;
  let  hide;
  if (this.state.active === true) { 
  signOutComp= this.status; 
  this.setState({ active: false });
  hide = this.props.overlay.hide();
   }else { 
   signOutComp= '';}

    return (      
       <Overlay context={overlay}> 
            {signOutComp}{hide} 
			<div className="main-menu__dropdown">
			    <div className="overlay__header">
						<ReactSVG
                            path={cartImg}
                            className="overlay__header__cart-icon"
                          />
                          <div className="overlay__header-text">
                            <Trans>My Account</Trans>
                            <span className="overlay__header-text-items">
                              
                            </span>
                          </div>
                          <ReactSVG
                            path={closeImg}
                            onClick={overlay.hide}
                            className="overlay__header__close-icon"
                          />
                        </div>
							<ul>
                              <li data-testid="my_account__link">
                                <Link to={appPaths.accountUrl}><Trans>Account</Trans></Link>
                              </li>
                              <li data-testid="order_history__link">
                                <Link to={appPaths.orderHistoryUrl}>
                                 <Trans>Order History</Trans>
                                </Link>
                              </li>
                              <li data-testid="address_book__link">
                                <Link to={appPaths.addressBookUrl}>
                                  <Trans>Address Book</Trans>
                                </Link>
                              </li>                    
                              <li
                                 onClick={(e) => this.handleSignOut(e)}
                                data-testid="logout-link"
                              >
                                <Trans>Log Out</Trans>
                              </li>
                            </ul>
							</div>
                            </Overlay>     
    
    );
  }
}

export default MenuDropdown;
