import React from "react";
import { Trans } from 'react-i18next';

const ForgottenPassword: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <>
    <div className="login__content__password-reminder">
      <p>
        <Trans>Have you forgotten your password?</Trans>&nbsp;
        <span className="u-link" onClick={onClick}>
          <Trans>Click Here</Trans>
        </span>
      </p>
    </div>
  </>
);

export default ForgottenPassword;
