import "./scss/index.scss";

import React from "react";
import { generatePath, Link, RouteComponentProps } from "react-router-dom";

import { guestOrderDetailsUrl } from "../../app/routes";
import { Button, NotFound } from "../../components";
import { BASE_URL } from "../../core/config";
import { Trans } from 'react-i18next';


type TState = { token: any, id: number };

const View: React.FC<RouteComponentProps> = ({
  history: {
    location: { state },
    replace,
  },
}) => {
  React.useEffect(() => {
    /**
     * Clear router state on leaving the page to ensure view becames unavailable
     * after leaving.
     */
    return () => {
      if (state) {
        replace({ ...location, state: undefined });
      }
    };
  }, []);

  /**
   * Token or id is passed from review page via router state. If it is not
   * present page should not be displayed.
   */
  if (!state) {
    return <NotFound />;
  }

  const { token } = state as TState;
  const orderDetailsRef = generatePath(guestOrderDetailsUrl, { token });

  return (
    <div className="order-confirmation">
      <h3>
        <Trans>Thank you for</Trans> <br /> <Trans>your order!</Trans>
      </h3>
      <p className="order-confirmation__info">
        <Trans>We’ve emailed you an order confirmation.</Trans>
        <br />
        <Trans>We’ll notify you when the order has been</Trans>
        <br />
        <Trans>shipped.</Trans>
      </p>
      <div className="order-confirmation__actions">
        <Link to={BASE_URL}>
          <Button secondary><Trans>Continue Shopping</Trans></Button>
        </Link>
        <Link to={orderDetailsRef}>
          <Button><Trans>Order Details</Trans></Button>
        </Link>
      </div>
    </div>
  );
};

export default View;
